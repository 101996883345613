<template>
  <b-row>
    <div
      v-if="items[0] && newOrderDialog"
      class="position-fixed d-flex justify-content-between flex-column p-5 dark-layout"
      style="
        left: 0;
        z-index: 999999999999999;
        right: 0;
        bottom: 0;
        top: 0;
        background: #12181f;
      "
    >
      <div class="d-flex justify-content-between">
        <b-avatar
          size="100px"
          :src="require('@/assets/images/logo/' + items[0].provider + '-icon-large.svg')"
        />
        <h2
          class=""
          style="
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #3b4253;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
          "
          @click="hideNewOrderDialog"
        >
          x
        </h2>
      </div>

      <div>
        <div class="font-large-3 font-weight-bolder h1">
          {{ items[0].full_name }} just ordered {{ items[0].totalItems }} items
        </div>
        <div
          class="font-large-1 text-white-50"
          style="
            max-width: 70vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ (items[0].items || []).map(item => item.name).join(', ') }}
        </div>
        <div
          class="font-large-1 h1 cursor-pointer user-select-none"
          style="
            max-width: 350px;
            white-space: nowrap;
            text-decoration: underline;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          @click="
            hideNewOrderDialog()
            order = items[0]
            orderDetailsDialog = true
          "
        >
          View order ->
        </div>
      </div>

      <div>
        <b-button size="xl" variant="primary" @click="hideNewOrderDialog"> Got it </b-button>
      </div>
    </div>
    <div class="d-none justify-content-around flex-wrap col-12">
      <b-col md="2" sm="4" class="my-1" hidden>
        <b-form-group class="">
          <label class="d-inline-block mr-1">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group>
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-50 d-inline"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" :disabled="!sortBy" class="w-25 d-inline">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="5" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="filterInput"
          class=""
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </div>
    <b-col v-if="!loadingOrders" cols="12" md="5" class="dense pr-0">
      <b-card style="height: 80vh; overflow-y: auto">
        <b-table
          hover
          responsive
          show-empty
          :borderless="true"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :tbody-tr-class="rowClass"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
        >
          <!--          <template #cell(id)="data">
            <div
              class="font-weight-bolder text-dark"
              style=" text-overflow: ellipsis;overflow: hidden"
            >
              #{{ data.item.displayId || data.value.slice(-5) }}
            </div>
          </template>-->
          <template #cell(full_name)="data">
            <div class="text-nowrap overflow-hidden full-name">
              <div
                class="font-weight-bolder text-dark full_name text-capitalize"
                style="text-overflow: ellipsis; overflow: hidden"
              >
                {{ data.value }}
              </div>
              <div class="text-nowrap">
                <b-badge pill :variant="paymentStatus[1][data.item.payment_method]">
                  {{ paymentStatus[0][data.item.payment_method] }}
                </b-badge>
              </div>
            </div>
          </template>
          <template #cell(table_number)="data">
            <div class="d-flex flex-column" style="text-overflow: ellipsis; overflow: hidden">
              <div
                class="font-weight-bolder font-small-3 text-dark full_name"
                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
              >
                #{{ data.item.table_number }}
              </div>
            </div>
          </template>

          <template #cell(status)="data">
            <div class="text-center d-flex flex-column justify-content-end align-items-center">
              <!--              <b-button
                v-if="data.value === 'placed'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                pill
                size="sm"
                variant="outline-success"
                @click="updateOrder(data.item.id, 'accept', `/${data.item.displayId}`)"
              >
                <b-spinner v-if="data.item['accept_' + data.item.id] === true" small />
                <span class="">Accept</span>
              </b-button>
              <b-button
                v-else-if="data.value === 'confirmed'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-dark"
                @click="updateOrder(data.item.id, 'complete')"
              >
                <b-spinner v-if="data.item['complete_' + data.item.id] === true" small />
                <span class="">Ready</span>
              </b-button>
              <b-button
                v-if="false"
                v-ripple.400="'rgba(200, 150, 150, 0.20)'"
                pill
                size="sm"
                variant="outline-danger"
                class="mr-1"
                @click="updateOrder(data.item.id, 'cancel')"
              >
                <b-spinner
                  v-show="data.item['cancel_' + data.item.id] === true"
                  style="margin:1px 3px"
                  small
                />
                <span class="">Cancel</span>
              </b-button>-->
              <div>
                <div class="d-flex flex-column">
                  <div class="text-center text-nowrap" style="margin-bottom: 4px">
                    <b-badge pill :variant="status[1][data.value]">
                      {{ status[0][data.value] }}
                    </b-badge>
                  </div>
                </div>
              </div>
              <div
                class="font-weight-bold font-small-3 text-dark"
                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
              >
                Order - #{{ data.item.displayId || data.item.id.slice(-5) }}
              </div>
            </div>
          </template>

          <!--          <template #cell(payment_method)="data">
            <div class="text-center text-nowrap">
              <b-badge pill :variant="paymentStatus[1][data.value]">
                {{ paymentStatus[0][data.value] }}
              </b-badge>
            </div>
          </template>-->
          <template #cell(placed)="data">
            <h5 class="text-dark font-weight-bolder">{{ data.value }}</h5>
            <h5>{{ `$${data.item.total.toFixed(2)}` }}</h5>
          </template>
          <template #cell(total)="data">
            <h5>{{ data.item.currencyCode || '$' }}{{ `${data.value}` }}</h5>
          </template>
          <template #cell(deliver_by)="data">
            <h5>{{ data.value }}</h5>
          </template>

          <template #empty="">
            <div class="d-flex justify-content-center flex-column align-items-center">
              <h3 v-if="!loadingOrders" class="m-4">No orders are available...</h3>
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="d-flex justify-content-center flex-column align-items-center">
              <h3 v-if="!loadingOrders" class="m-4">
                {{ scope.emptyFilteredText }}
              </h3>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col cols="12" md="7" class="dense">
      <OrderDetails
        :bus="bus"
        :order="order"
        :is-delivery="false"
        :order-details-dialog="orderDetailsDialog"
        @closeDialog="orderDetailsDialog = false"
        @complete="updateOrder($event, 'ready')"
        @adjust="updateOrder($event.orderID, 'adjust', null, $event)"
        @cancel="updateOrder($event, 'cancelled')"
        @close="updateOrder($event, 'closed')"
      />
    </b-col>
    <div
      v-if="loadingOrders"
      class="d-flex justify-content-center flex-column align-items-center w-100 m-3"
    >
      <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
      <div class="m-2">Loading Orders, Please wait...</div>
    </div>
    <b-col class="d-none" cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        size="sm"
        class="my-0 text-center d-flex justify-content-center"
      />
    </b-col>
  </b-row>
</template>
<style scoped>
@media all and (min-width: 768px) {
  .full-name {
    max-width: 12vw;
  }
}
@media all and (max-width: 768px) {
  .full-name {
    max-width: 33vw;
  }
}
</style>
<style>
.highlight-row {
  background: red;
}
</style>
<script>
/* eslint-disable no-console,no-param-reassign,no-underscore-dangle */
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BCard,
  BSpinner,
  BTable,
  VBModal,
} from 'bootstrap-vue'
import OrderDetails from '@/layouts/components/OrderDetails.vue'
import Ripple from 'vue-ripple-directive'
import { AsYouType } from 'libphonenumber-js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

// Fix up prefixing
window.AudioContext = window.AudioContext || window.webkitAudioContext

export default {
  components: {
    BSpinner,
    BBadge,
    BAvatar,
    BTable,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    OrderDetails,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      bus: new Vue(),
      perPage: 50,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // {
        //   key: 'id',
        //   label: '#Id',
        //   sortable: true,
        // },
        // {
        //   key: 'table_number',
        //   label: 'Table',
        //   sortable: true,
        // },
        {
          key: 'full_name',
          label: 'Customer',
          sortable: true,
        },
        {
          key: 'table_number',
          label: 'Table',
          sortable: true,
        },
        { key: 'status', label: 'Status', sortable: true },
        // { key: 'total', label: 'Total', sortable: true },
        // { key: 'payment_method', label: 'Payment', sortable: true },
      ],
      items: [],
      status: [
        {
          placed: 'Placed',
          confirmed: 'Confirmed',
          ready: 'Ready',
          cancelled: 'Cancelled',
          closed: 'Closed',
        },
        {
          placed: 'light-primary',
          confirmed: 'light-primary',
          ready: 'light-success',
          cancelled: 'light-danger',
          closed: 'dark',
        },
      ],
      paymentStatus: [
        {
          1: 'Paid by CC',
          2: 'Cash- Collect Payment',
        },
        {
          1: 'light-success',
          2: 'light-danger',
        },
      ],
      loadingOrders: true,
      orderDetailsDialog: false,
      newOrderDialog: false,
      order: {
        items: [],
      },
      lastUpdate: new Date(new Date().getTime() - 48 * 60 * 60 * 1000).toISOString(),
      checkOrderInterval: undefined,
      keySoundBuffer: undefined,
      context: new AudioContext(),
      source: false,
      reprinting: true,
      spinner: {
        complete: false,
        cancel: false,
        reprintKitchen: false,
        reprintBar: false,
        reprintCustomer: false,
        close: false,
        adjust: false,
      },
      loadingButtons: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.checkOrderInterval)
    this.source = false
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  async created() {
    const This = this
    const url = 'audios/notification.mp3'
    const request = new XMLHttpRequest()
    request.open('GET', url, true)
    request.responseType = 'arraybuffer'
    request.onload = () => {
      This.context.decodeAudioData(
        request.response,
        buffer => {
          This.keySoundBuffer = buffer
        },
        () => {
          console.error('The request failed.')
        }
      )
    }
    request.send()

    function unlockAudioContext(ctx) {
      if (ctx.state !== 'suspended') return
      const b = document.body
      const events = ['touchstart', 'touchend', 'mousedown', 'keydown']
      // eslint-disable-next-line no-use-before-define
      events.forEach(e => b.addEventListener(e, unlock, false))
      function clean() {
        // eslint-disable-next-line no-use-before-define
        events.forEach(e => b.removeEventListener(e, unlock))
      }
      function unlock() {
        ctx.resume().then(clean)
      }
    }
    unlockAudioContext(this.context)

    await this.getOrders()
    if (this.items[0]) this.onRowClicked(this.items[0])
    if (this.checkOrderInterval) clearInterval(this.checkOrderInterval)
    this.checkOrderInterval = setInterval(async () => {
      await this.getOrders(true)
    }, 10000)
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onRowClicked(item, index, event) {
      // eslint-disable-next-line no-underscore-dangle,no-param-reassign
      this.order = item
      this.orderDetailsDialog = true
      // this.orderDetailsDialog = !this.orderDetailsDialog
    },
    hideNewOrderDialog() {
      this.newOrderDialog = false
      if (this.source) {
        this.source.stop(0)
        this.source = false
      }
    },
    playSound(buffer, time, volume, ctx) {
      console.log('play now')
      if (ctx.state === 'suspended') return false
      console.log('playing')
      const source = ctx.createBufferSource() // creates a sound source
      source.buffer = buffer // tell the source which sound to play
      source.connect(ctx.destination) // connect the source to the context's destination (the speakers)
      source.start(time) // play the source at the deisred time 0=now
      source.loop = true
      return source
    },
    getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    async getOrders(hideRefreshing) {
      if (!hideRefreshing) this.loadingOrders = true
      try {
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`orders?dinein=true&time=${this.lastUpdate}`)
        response = response.data
        const { data } = response.orders[0]
        if (response.type === 'success' && response.orders && data.length > 0) {
          data.sort(this.compare)
          data.filter(order => {
            if (!order.provider || !order.store) {
              console.error(order)
              return false
            }
            return true
          })
          const newOrders = data.map(
            /**
             *
             * @param {Object} order
             * @returns
             * {{delivery: *, placed: string, scheduled: *, payment_method: *, delivery_instructions: *, tax: *, store: {}, deliver_by: string, table_id: *, total, full_name: *, provider: *, phone: string, subtotal: *, fullOrder: *, tip, _id: *, id, displayId, items, status: (number)}}
             */
            order => {
              if (order.placed > this.lastUpdate) this.lastUpdate = order.placed
              let orders = {
                // eslint-disable-next-line no-underscore-dangle
                _id: order._id,
                provider: order.provider,
                status: order.status,
                full_name: order.full_name,
                placed: new Date(order.placed).toString().slice(16, 21),
                deliver_by: new Date(order.deliver_by).toString().slice(16, 21),
                id: order.id,
                note: order.note,
                table_number: order.table_number,
                payment_method:
                  order.payment_method && order.payment_method.toLowerCase().includes('cash')
                    ? 2
                    : 1,
                scheduled: order.scheduled,
                delivery_instructions: order.delivery_instructions,
                subtotal: order.subtotal,
                tax: order.tax,
                tip: order.tip,
                total: order.total,
                phone: new AsYouType('US').input(`${order.phone}`),
                displayId: order.displayId || order.id,
                store: order.store || {},
                items: order.items,
                fullOrder: order.fullOrder,
                delivery: order.delivery,
              }
              if (orders.items) {
                orders.totalItems = orders.items.reduce(
                  (total, current) => total + current.quantity,
                  0
                )
              }
              // console.log(orders.totalItems)
              orders = Object.assign(order, orders)
              return orders
            }
          )
          if (newOrders.length > 0) {
            if (this.items.length > 0) {
              console.log('dispatching printbar', newOrders.length)
              this.bus.$emit('printBar', newOrders)
              if (!this.source)
                this.source = this.playSound(this.keySoundBuffer, 0, 1, this.context)
              this.items = newOrders.concat(this.items)
              this.newOrderDialog = true
            } else this.items = newOrders
          }
          this.totalRows = this.items.length
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        } else if (data && data.length !== 0) {
          console.error(response)
          this.$bvToast.toast('Something went wrong', {
            title: 'Error!',
            variant: 'info',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      }
      this.loadingOrders = false
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async updateOrder(orderID, update = 'complete', uidQuery = '', args) {
      console.log(args)
      // if (update === 'cancel') return
      this.spinner[update] = true
      try {
        // start spinner
        this.items = this.items.map(order => {
          if (order.id === orderID) {
            order[`${update}_${orderID}`] = true
          }
          return order
        })
        /**
         * @type {Object}
         */
        let axiosResponse
        if (update === 'adjust') {
          axiosResponse = await this.$http.post(`integrations/omnimenu/${update}-order/`, {
            orderID,
            payment_intent: args.payment_intent,
            amount: args.amount,
            type: args.type,
            reason: args.reason,
          })
        } else
          axiosResponse = await this.$http.get(
            `integrations/omnimenu/${update}-order/${orderID}${uidQuery}`
          )
        const { data } = axiosResponse
        if (data.type === 'success') {
          if (update === 'accept') {
            this.items = this.items.map(order => {
              if (order.id === orderID) order.status = 'confirmed'
              return order
            })
          } else if (update === 'complete') {
            this.items = this.items.map(order => {
              if (order.id === orderID) order.status = 'completed'
              return order
            })
          } else if (update === 'cancel') {
            this.items = this.items.map(order => {
              if (order.id === orderID) order.status = 'cancelled'
              return order
            })
          } else if (update === 'adjust') {
            this.items = this.items.map(order => {
              if (order.id === orderID) {
                order.total -= args.amount
                order.adjustments = {
                  amount: args.amount,
                  reason: args.reason,
                }
              }
              return order
            })
          } else if (update === 'close') {
            this.items = this.items.filter(order => order.id !== orderID)
          } /* else
            this.items = this.items.filter(order => {
              return order.id !== orderID
            }) */
          this.showToast('Successfully updated', 'CheckIcon', 'success')
        } else if (data.type === 'error') {
          console.error(data.display)
          this.showToast('Error!', 'AlertTriangleIcon', 'danger', data.display)
        }
      } catch (e) {
        console.error(e)
        this.showToast('Error!', 'AlertTriangleIcon', 'danger', e.message)
      }
      // stop spinner
      this.spinner[update] = false
      this.items = this.items.map(order => {
        if (order.id === orderID) {
          order[`${update}_${orderID}`] = false
        }
        return order
      })
    },
    /**
     * @param {String} a.placed
     * @param {String} b.placed
     * @returns {number}
     */
    compare(a, b) {
      if (a.placed > b.placed) {
        return -1
      }
      if (a.placed < b.placed) {
        return 1
      }
      return 0
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClass(item, type) {
      const colorClass = 'table-active'
      if (!item || type !== 'row') {
        return
      }

      if (item.id === this.order.id) {
        // eslint-disable-next-line consistent-return
        return colorClass
      }
    },
  },
}
</script>
