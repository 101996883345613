<template>
  <b-card
    v-show="orderDetailsDialog && order"
    ref="myModalRef"
    style="height: 80vh; overflow-y: auto; overflow-x: hidden"
    @hide="closeModel"
  >
    <b-row class="h-100">
      <b-col md="9" class="d-flex flex-column">
        <div
          class="font-weight-bolder text-center font-medium-5 text-dark"
          style="font-family: Arial, sans-serif"
        >
          {{
            `${order.totalItems} ${order.totalItems > 1 ? 'items' : 'item'} for ` +
            (order.full_name || 'sdfsdfsd').charAt(0).toUpperCase() +
            (order.full_name || '').slice(1)
          }}
        </div>
        <div
          class="font-weight-bolder text-center font-medium-2 text-dark"
          style="font-family: Arial, sans-serif"
        >
          Ordered at: {{ order.placed }}
        </div>
        <h4 v-if="isDelivery" class="text-center">
          {{ (order.store || {}).name }} • {{ (order.store || {}).location }}
          <span class="d-inline-block">
            •
            {{
              (order.provider || 'sdfsdfsd').charAt(0).toUpperCase() +
              (order.provider || '').slice(1)
            }}</span
          >
          <span class="text-nowrap">#{{ order.displayId }}</span>
        </h4>
        <h5
          v-if="isDelivery && order.printable_address && order.printable_address !== ''"
          class="d-flex flex-column justify-content-center text-center font-medium-2"
        >
          <br />
          <br />
          {{ order.printable_address }}
          <br />
        </h5>
        <h5
          v-if="
            isDelivery &&
            order.delivery_instructions &&
            order.delivery_instructions.trim() !== ''
          "
          class="text-center font-medium-2"
        >
          <span class="font-weight-bolder text-dark">Delivery instructions: </span
          ><span class="ml-1"> {{ order.delivery_instructions }}</span>
          <br />
        </h5>
        <br />
        <h5 v-if="order.note && order.note.trim() !== ''" class="text-center font-medium-1">
          <span class="font-weight-bolder text-primary">Note: </span
          ><span class="ml-1"> {{ order.note }}</span>
        </h5>
        <div class="d-flex flex-column flex-grow-1 justify-content-between">
          <div class="items overflow-auto flex-grow-1" style="flex-basis: 0px">
            <div v-for="(item, index) of order.items" :key="index" class="item">
              <div class="item-name d-flex align-items-center">
                <span class="round-chip bg-dark dark-layout h5">
                  {{ item.quantity }}
                </span>
                <div class="item" style="width: 100%; margin-top: 3px">
                  <div
                    class="ml-1 text-dark font-medium-2 font-weight-bold d-flex justify-content-between"
                  >
                    <span class="">{{ item.name }}</span
                    ><span class="ml-1"
                      >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
                      }}{{ item.price }}</span
                    >
                  </div>
                  <div
                    v-if="item.specialInstructions && item.specialInstructions.length > 0"
                    class="special-instructions ml-1"
                  >
                    <div
                      v-for="(instruction, i) of item.specialInstructions"
                      :key="i"
                      class="special-instruction mb-1"
                    >
                      <span
                        v-if="instruction && instruction"
                        class="h6 font-medium-1 d-flex justify-content-between"
                      >
                        <span v-if="instruction.title"
                          ><span class="text-dark font-weight-bolder">•</span>
                          {{ instruction.title }}
                        </span>
                        <span v-if="instruction.price" class="ml-1"
                          >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
                          }}{{ instruction.price }}</span
                        >
                      </span>
                      <div v-if="instruction.subtitles" class="subtitles">
                        <div
                          v-for="(subtitle, k) of instruction.subtitles"
                          :key="k"
                          class="special-instruction d-flex justify-content-between"
                        >
                          <span v-if="subtitle && subtitle.title" class="h6">
                            <span class="text-dark font-weight-bolder">--</span>
                            {{ subtitle.quantity }}x {{ subtitle.title }}</span
                          >
                          <span v-if="subtitle && subtitle.price" class="h6 ml-1">
                            {{ order.currencyCode ? order.currencyCode + ' ' : '$'
                            }}{{ subtitle.price }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 v-if="item.note" class="specialInstructions ml-2 mt-1">
                    <span class="font-weight-bolder text-primary">Note: </span
                    ><span> {{ item.note }}</span>
                  </h5>
                  <h5 v-if="item.allergy && item.allergy.length > 0" class="allergy ml-2 mt-1">
                    <span class="font-weight-bolder text-warning">Allergy: </span
                    ><span> {{ item.allergy.map(allergy => allergy.name).join(', ') }}</span>
                  </h5>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div class="totals font-medium-1 text-dark">
            <div class="total mb-1 d-flex justify-content-center" style="width: 100%">
              <span>Subtotal</span>
              <span class="line-spacer" />
              <span
                >{{ order.currencyCode ? order.currencyCode + ' ' : '$' }}
                {{ order.subtotal ? `${order.subtotal}` : '0' }}</span
              >
            </div>
            <div class="total mb-1 d-flex justify-content-center" style="width: 100%">
              <span>Tax</span>
              <span class="line-spacer" />
              <span
                >{{ order.currencyCode ? order.currencyCode + ' ' : '$'
                }}{{ order.tax ? `${order.tax.toFixed(2)}` : '0' }}</span
              >
            </div>
            <div
              v-if="order.tip"
              class="total mb-1 d-flex justify-content-center"
              style="width: 100%"
            >
              <span>Tip</span>
              <span class="line-spacer" />
              <span>
                {{ order.currencyCode ? order.currencyCode + ' ' : '$'
                }}{{ order.tip ? `${order.tip}` : 0 }}</span
              >
            </div>
            <div class="total mb-1 d-flex justify-content-center" style="width: 100%">
              <span>Total</span>
              <span class="line-spacer" />
              <span
                >{{ order.currencyCode || '$'
                }}{{ order.total ? order.total.toFixed(2) : '0' }}</span
              >
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="3" class="">
        <div
          class="dine-in-buttons d-flex justify-content-center justify-content-around flex-wrap overflow-auto"
        >
          <b-button
            class="mb-1"
            :disabled="$parent.spinner['reprintKitchen']"
            :variant="$parent.spinner['reprintKitchen'] ? 'dark' : 'dark'"
            @click="reprint(order.id)"
          >
            <b-spinner v-if="$parent.spinner['reprintKitchen']" small class="" />
            <span>{{
              $parent.spinner['reprintKitchen'] ? 'Reprinting' : 'Reprint kitchen Ticket'
            }}</span>
          </b-button>
          <b-button
            class="mb-1 w-100"
            :disabled="$parent.spinner['reprintBar']"
            :variant="$parent.spinner['reprintBar'] ? 'dark' : 'dark'"
            @click="printBar(order)"
          >
            <b-spinner v-if="$parent.spinner['reprintBar']" small class="" />
            <span>{{
              $parent.spinner['reprintBar'] ? 'Reprinting' : 'Reprint Bar Ticket'
            }}</span>
          </b-button>
          <b-button
            class="mb-1"
            :disabled="$parent.spinner['reprintCustomer']"
            :variant="$parent.spinner['reprintCustomer'] ? 'dark' : 'dark'"
            @click="printCustomer(order)"
          >
            <b-spinner v-if="$parent.spinner['reprintCustomer']" small class="" />
            <span>{{
              $parent.spinner['reprintCustomer']
                ? 'Reprint Receipt'
                : 'Print customer’s Receipt'
            }}</span>
          </b-button>
          <adjust-order :order="order"></adjust-order>

          <b-button
            class="mb-1"
            :disabled="$parent.spinner['ready'] || order.status !== 'confirmed'"
            :variant="$parent.spinner['complete'] ? 'dark' : 'dark'"
            @click="$emit('complete', order.id)"
          >
            <b-spinner v-if="$parent.spinner['complete']" small class="" />
            <span>{{
              $parent.spinner['complete'] ? 'Marking as Ready' : 'Mark as Ready'
            }}</span>
          </b-button>
          <b-button
            class="mb-1"
            :disabled="$parent.spinner['cancelled'] || order.status === 'closed'"
            :variant="$parent.spinner['cancelled'] ? 'dark' : 'dark'"
            @click="$emit('cancelled', order.id)"
          >
            <b-spinner v-if="$parent.spinner['cancelled']" small class="" />
            <span>{{ $parent.spinner['cancelled'] ? 'Voiding' : 'Void Order' }}</span>
          </b-button>
          <b-button
            class="mb-1"
            :disabled="
              $parent.spinner['closed'] ||
              !(order.status === 'ready' || order.status === 'cancelled')
            "
            :variant="$parent.spinner['closed'] ? 'dark' : 'dark'"
            @click="$emit('closed', order.id)"
          >
            <b-spinner v-if="$parent.spinner['closed']" small class="" />
            <span>{{ $parent.spinner['closed'] ? 'Closing' : 'Close Order' }}</span>
          </b-button>
          <b-button class="mb-1" variant="dark" @click="printerConnectionDialog = true">
            <span>Printers</span>
          </b-button>
        </div>
      </b-col>

      <b-modal v-model="printerConnectionDialog" title="Printers" hide-footer>
        <b-tabs fill>
          <b-tab title="bar">
            <b-form-input
              v-model="printerConnection.bar.ipAddress"
              class="mb-1"
            ></b-form-input>
            <b-form-input v-model="printerConnection.bar.port" class="mb-1"></b-form-input>
            <b-form-input
              v-model="printerConnection.bar.deviceID"
              class="mb-1"
              x-placement="device id"
            ></b-form-input>
            <b-button class="mb-1" @click="initBarPrinter">Save & reconnect</b-button>
            <br />
            <a
              target="_blank"
              rel="noopener"
              :href="'https://' + printerConnection.bar.ipAddress"
              >Printer Setting</a
            >
            <br />
            {{ barStatus }}
            <br />
            {{ barStatus2 }}
          </b-tab>
          <b-tab title="kitchen">
            <b-form-input
              v-model="printerConnection.kitchen.ipAddress"
              class="mb-1"
            ></b-form-input>
            <b-form-input v-model="printerConnection.kitchen.port" class="mb-1"></b-form-input>
            <b-form-input
              v-model="printerConnection.kitchen.deviceID"
              class="mb-1"
              x-placement="device id"
            ></b-form-input>
            <b-button class="mb-1" @click="initBarPrinter">Save & reconnect</b-button>
            <br />
            <a
              target="_blank"
              rel="noopener"
              :href="'https://' + printerConnection.kitchen.ipAddress"
              >Printer Setting</a
            >
            <br />
            {{ kitchenStatus }}
            <br />
            {{ kitchenStatus2 }}
          </b-tab>
          <b-tab title="customer">
            <b-form-input
              v-model="printerConnection.customer.ipAddress"
              class="mb-1"
            ></b-form-input>
            <b-form-input
              v-model="printerConnection.customer.port"
              class="mb-1"
            ></b-form-input>
            <b-form-input
              v-model="printerConnection.customer.deviceID"
              class="mb-1"
              x-placement="device id"
            ></b-form-input>
            <b-button class="mb-1" @click="initBarPrinter">Save & reconnect</b-button>
            <br />
            <a
              target="_blank"
              rel="noopener"
              :href="'https://' + printerConnection.customer.ipAddress"
              >Printer Setting</a
            >
            <br />
            {{ customerStatus }}
            <br />
            {{ customerStatus2 }}
          </b-tab>
        </b-tabs>
      </b-modal>
    </b-row>
  </b-card>
</template>
<style scoped>
.dine-in-buttons button {
  width: 100%;
  padding: 0.586rem !important;
}
</style>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<script>
/* eslint-disable no-console,no-param-reassign,no-underscore-dangle */

import {
  BButton,
  BSpinner,
  BCard,
  BRow,
  BCol,
  VBModal,
  BModal,
  BTabs,
  BTab,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import AdjustOrder from './AdjustOrder.vue'

// Fix up prefixing
window.AudioContext = window.AudioContext || window.webkitAudioContext

export default {
  components: {
    AdjustOrder,
    BSpinner,
    BButton,
    BCard,
    BRow,
    BCol,
    BModal,
    BTabs,
    BTab,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    bus: {
      type: Vue,
      default: null,
    },
    orderDetailsDialog: {
      type: Boolean,
      default: () => false,
    },
    isDelivery: {
      type: Boolean,
      default: () => true,
    },
    order: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
  },
  data() {
    return {
      /*eslint-disable */
      printer: null,
      printerConnection:
        !localStorage.getItem('printers') || localStorage.getItem('printers') === 'undefined'
          ? {
              bar: {
                ipAddress: '192.168.32.41',
                port: '8043',
                deviceID: 'local_printer',
              },
              kitchen: {
                ipAddress: '192.168.32.42',
                port: '8043',
                deviceID: 'local_printer',
              },
              customer: {
                ipAddress: '192.168.32.30',
                port: '8043',
                deviceID: 'local_printer',
              },
            }
          : JSON.parse(localStorage.getItem('printers')),
      barStatus: '',
      barStatus2: '',
      kitchenStatus: '',
      kitchenStatus2: '',
      customerStatus: '',
      customerStatus2: '',
      printerConnectionDialog: false,
      ePosDev: new epson.ePOSDevice(),
      reprinting: false,
    }
  },
  created() {
    this.initBarPrinter()
    this.bus.$once('printBar', this.prntOrders)
  },
  beforeDestroy() {
    this.bus.$off('printBar', this.prntOrders)
  },
  methods: {
    async prntOrders(newOrders) {
      console.log('event received', 'printBar')
      console.log(newOrders)
      if (newOrders)
        for (const newOrder of newOrders) {
          await this.printBar(newOrder)
        }
    },
    async printBar(order) {
      console.log('printing', order.id)

      if (!this.printer) {
        this.$bvToast.toast(
          'Please try restarting printer or changing ip address in setting',
          {
            title: 'Printer Not Connected',
            variant: 'danger',
            solid: false,
          }
        )
        return
      }
      let items = 0
      this.printer.addTextLang('en')
      this.printer.addTextSmooth(true)
      this.printer.addTextAlign('center')
      this.printer.addTextFont('font_a')

      this.printer.addTextStyle(false, false, false, 'color_1')
      this.printer.addTextSize(2, 1)
      this.printer.addText(`*********************`)
      this.printer.addFeedUnit(40)
      this.printer.addText(`Order - #${order.displayId}`)
      this.printer.addFeed(30)
      this.printer.addText(`Table - ${order.table_number}`)
      this.printer.addFeed(30)
      this.printer.addTextStyle(false, false, true, 'color_1')
      this.printer.addText(`Placed - `)
      this.printer.addTextStyle(false, false, false, 'color_1')
      this.printer.addText(`${order.placed}`)

      this.printer.addFeedUnit(50)
      this.printer.addTextStyle(false, false, true, 'color_1')
      this.printer.addText(`Customer: `)
      this.printer.addTextStyle(false, false, false, 'color_1')
      this.printer.addText(`${order.full_name}`)
      this.printer.addFeedUnit(70)

      if (order.note) {
        this.printer.addTextStyle(false, false, true, 'color_1')
        this.printer.addText(`Note - `)
        this.printer.addTextStyle(false, false, false, 'color_1')
        this.printer.addText(order.note)
        this.printer.addFeedUnit(50)
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const item of order.items) {
        console.log((item.printer || '').toLowerCase())
        if ((item.printer || '').toLowerCase() !== 'bar') continue
        items++
        this.printer.addTextAlign('left')
        this.printer.addTextStyle(false, false, true, 'color_1')
        this.printer.addText(`${item.quantity}x `)
        this.printer.addText(`${item.name}`)
        this.printer.addTextStyle(false, false, false, 'color_1')
        this.printer.addFeedUnit(40)
        if (item.specialInstructions) {
          // eslint-disable-next-line no-restricted-syntax
          for (const choice of item.specialInstructions) {
            if (choice.title) {
              this.printer.addTextPosition(20)
              this.printer.addText(`-${choice.title}`)
              this.printer.addFeedUnit(30)
            }
            if (choice.subtitles && choice.subtitles.length > 0) {
              // eslint-disable-next-line no-restricted-syntax
              for (const subtitle of choice.subtitles) {
                if (subtitle.title) {
                  this.printer.addTextPosition(35)
                  this.printer.addText(`--${subtitle.title || subtitle}`)
                  this.printer.addFeedUnit(30)
                }
              }
            }
          }
        }
        this.printer.addTextAlign('left')

        if (item.allergy && item.allergy.length > 0) {
          this.printer.addTextStyle(false, false, true, 'color_1')
          this.printer.addText(`Allergy: `)
          this.printer.addTextStyle(false, false, false, 'color_1')
          this.printer.addText(item.allergy.map(allergy => allergy.name).join(', '))
        }
      }
      this.printer.addFeedUnit(30)
      this.printer.addTextAlign('center')
      this.printer.addText(`*********************`)

      this.printer.addFeedUnit(70)
      this.printer.addCut(this.printer.CUT_FEED)
      if (items === 0) return
      this.printer.send()
    },
    async printCustomer(order) {
      console.log('printing', order.id)

      this.printer.addTextLang('en')
      this.printer.addTextSmooth(true)
      this.printer.addTextAlign('center')
      this.printer.addTextFont('font_a')
      this.printer.addTextDouble(true, true)
      this.printer.addTextStyle(false, false, true, 'color_1')
      this.printer.addFeed(30)
      this.printer.addText(order.provider)
      this.printer.addFeed(30)

      this.printer.addTextDouble(false, false)
      this.printer.addTextStyle(false, false, false, 'color_1')
      this.printer.addText(`Order - #${order.displayId}`)
      this.printer.addFeed(30)
      this.printer.addText(`Table - ${order.table_number}`)
      this.printer.addFeed(30)
      this.printer.addTextStyle(false, false, true, 'color_1')
      this.printer.addText(`Placed - `)
      this.printer.addTextStyle(false, false, false, 'color_1')
      this.printer.addText(`${order.placed}`)

      this.printer.addFeedUnit(50)
      this.printer.addTextStyle(false, false, true, 'color_1')
      this.printer.addText(`Customer Info`)
      this.printer.addFeed(30)
      this.printer.addTextStyle(false, false, false, 'color_1')
      this.printer.addText(`${order.full_name}`)
      this.printer.addFeed(30)
      this.printer.addText(`${order.phone}`)
      this.printer.addFeed(40)

      if (order.note) {
        this.printer.addTextStyle(false, false, true, 'color_1')
        this.printer.addText(`Note - `)
        this.printer.addTextStyle(false, false, false, 'color_1')
        this.printer.addText(order.note)
        this.printer.addFeedUnit(40)
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const item of order.items) {
        this.printer.addTextAlign('left')
        this.printer.addTextStyle(false, false, true, 'color_1')
        this.printer.addText(`${item.quantity}x `)
        this.printer.addText(` ${item.name}`)
        this.printer.addTextStyle(false, false, false, 'color_1')
        this.printer.addTextAlign('right')
        this.printer.addTextPosition(413)
        this.printer.addText(`$${item.price}`)
        this.printer.addTextAlign('left')
        this.printer.addFeedUnit(40)
        if (item.specialInstructions) {
          // eslint-disable-next-line no-restricted-syntax
          for (const choice of item.specialInstructions) {
            if (choice.title) {
              this.printer.addText(`$${choice.title}`)
              this.printer.addFeedUnit(30)
            }
            if (choice.subtitles && choice.subtitles.length > 0) {
              // eslint-disable-next-line no-restricted-syntax
              for (const subtitle of choice.subtitles) {
                if (subtitle.title) {
                  this.printer.addText(`${subtitle.title || subtitle}`)
                  this.printer.addTextPosition(413)
                  this.printer.addText(`${subtitle.price}`)
                  this.printer.addFeedUnit(30)
                }
              }
            }
          }
        }

        if (item.allergy && item.allergy.length > 0) {
          this.printer.addTextStyle(false, false, true, 'color_1')
          this.printer.addText(`Allergy: `)
          this.printer.addTextStyle(false, false, false, 'color_1')
          this.printer.addText(item.allergy.map(allergy => allergy.name).join(', '))
          this.printer.addFeedUnit(70)
        }
      }

      this.printer.addTextAlign('center')
      this.printer.addText('------------------------------')
      this.printer.addFeedUnit(60)
      this.printer.addTextAlign('left')
      this.printer.addTextPosition(90)
      this.printer.addText(` Subtotal:           $${order.subtotal}`)
      this.printer.addFeedUnit(30)
      this.printer.addTextPosition(90)
      this.printer.addText(` Tax:                $${order.tax}`)
      if (order.tip) {
        this.printer.addFeedUnit(30)
        this.printer.addTextPosition(90)
        this.printer.addText(` Tip:                $${order.tip}`)
      }
      this.printer.addFeedUnit(30)
      this.printer.addTextPosition(90)
      this.printer.addText(` Total:              $${order.total}`)
      this.printer.addFeedUnit(70)

      this.printer.addTextAlign('center')
      this.printer.addText('Thanks for the order.')
      this.printer.addFeedUnit(100)
      this.printer.addTextAlign('left')

      this.printer.addTextFont('font_b')

      // this.printer.addTextDouble(false, true)
      // this.printer.addText('Total 3656L.\n')

      this.printer.addCut(this.printer.CUT_FEED)
      this.printer.send()
    },
    initBarPrinter() {
      this.barStatus = 'connnecting...'
      this.barStatus2 = ''
      localStorage.setItem('printers', JSON.stringify(this.printerConnection))
      this.ePosDev.connect(
        this.printerConnection.bar.ipAddress,
        this.printerConnection.bar.port,
        data => this.cbConnect(data)
      )
    },
    cbConnect(data) {
      console.log(data)
      const options = { crypto: false, buffer: false }
      if (data === 'OK' || data === 'SSL_CONNECT_OK') {
        this.barStatus = 'connected to ePOS Device Service Interface.'
        this.ePosDev.createDevice(
          this.printerConnection.bar.deviceID,
          this.ePosDev.DEVICE_TYPE_PRINTER,
          options,
          (printer, retcode) => this.cbCreateDevicePrinter(printer, retcode)
        )
      } else {
        this.barStatus = `connected to ePOS Device Service Interface is failed. [${data}]`
      }
    },
    cbCreateDevicePrinter(printer, retcode) {
      if (retcode === 'OK') {
        this.printer = printer
        this.printer.timeout = 60000
        this.printer.onreceive = function (res) {
          this.barStatus2 = res.success
        }
        this.printer.oncoveropen = function () {
          this.barStatus2 = 'coveropen'
        }
        // this.print()
      } else {
        this.barStatus2 = retcode
      }
    },
    async reprint(orderID) {
      try {
        this.reprinting = true
        const response = (await this.$http.get(`reprint-order?orderID=${orderID}`)).data
        console.log(response)
        if (response.printed) {
          this.$bvToast.toast('Reprint request submitted successfully', {
            title: 'Success!',
            variant: 'success',
            solid: true,
          })
        } else if (response.display) {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        } else {
          this.$bvToast.toast('something went wrong Error#904932', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.reprinting = false
      }
    },
    closeModel() {
      this.$emit('close', true)
    },
  },
}
</script>
