<template>
  <b-button
    :disabled="!(order.status === 'completed' || order.status === 'confirmed')"
    class="mb-1"
    variant="dark"
    @click="showAdjustDialog = true"
  >
    <span>Adjust Order</span>
    <b-modal v-model="showAdjustDialog" hide-footer no-close-on-backdrop>
      <b-tabs fill>
        <b-tab title="Items">
          <div class="text-center font-weight-bold my-1">Select Items to remove</div>
          <div v-if="order.items.length" style="max-height: 60vh; overflow-y: auto">
            <div>
              <!--                Selected: <strong>{{ selectedModifiers }}</strong>-->
              voided: {{ order.items[0].specialInstructions[0].subtitles[0].voided }}
              {{ order.items[0].voided }} {{ order.country }}
            </div>
            <div
              v-for="(item, index) in order.items"
              :key="item.name + index"
              class="d-flex p-1"
              style="border-top: 1px solid #cccccc55"
            >
              <b-form-group class="w-100">
                <b-form-checkbox-group id="full-width-checkbox-0" name="selectedUsers">
                  <b-form-checkbox
                    :key="index"
                    :value="item"
                    class="custom-control-danger d-flex justify-content-between"
                    size="default"
                    switch
                    @change="
                      event => {
                        item.voided = !item.voided
                        /* eslint-disable-next-line vue/no-mutating-props */
                        order.country = Math.random().toString()
                      }
                    "
                  >
                    <div class="d-flex justify-content-between flex-grow-1">
                      <div>
                        <div class="bg-white text-dark font-weight-bolder" style="">
                          {{ item.name }}
                        </div>

                        <div v-if="item" class="modifiers-list m-1">
                          <div
                            v-for="specialInstruction in item.specialInstructions"
                            :key="specialInstruction.title"
                            class="modifier"
                          >
                            <div class="font-weight-bold font-small-4 text-dark">
                              {{ specialInstruction.title }}
                            </div>
                            <div class="my-0">
                              <b-form-group>
                                <b-form-checkbox-group
                                  id="full-width-checkbox"
                                  name="selectedUsers"
                                >
                                  <b-form-checkbox
                                    v-for="(subtitle, index2) in specialInstruction.subtitles"
                                    :key="index2"
                                    :value="subtitle"
                                    class="custom-control-danger d-flex justify-content-between my-1"
                                    size="default"
                                    switch
                                    @click="$event.stopPropagation()"
                                    @change="
                                      event => {
                                        subtitle.voided = !subtitle.voided
                                        /* eslint-disable-next-line vue/no-mutating-props */
                                        order.country = Math.random().toString()
                                      }
                                    "
                                  >
                                    <div
                                      class="d-flex justify-content-between font-weight-bold"
                                    >
                                      <div class="text-dark font-small-4">
                                        {{ subtitle.title }}
                                      </div>
                                      <div class="text-dark font-small-4 ml-1">
                                        +${{ subtitle.price }}
                                      </div>
                                    </div>
                                  </b-form-checkbox>
                                </b-form-checkbox-group>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-around">
                        <div
                          class="d-flex flex-column align-items-center justify-content-around h-100"
                        >
                          <h5 class="mx-1">${{ item.price }}</h5>
                        </div>
                        <b-form-spinbutton
                          v-model="item.quantity"
                          :min="0"
                          size="sm"
                          vertical
                          @change="
                            /* eslint-disable-next-line vue/no-mutating-props */
                            order.country = Math.random().toString() + ' ' + item.quantity
                          "
                        />
                        <!--                @change="updateItemQuantity(item.cartItemId, $event)"-->
                      </div>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
          <b-form-group>
            <v-select
              v-model="selectedOrderCancelReasonItems"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="orderCancelReasonsItems"
              :reduce="reason => reason.value"
              label="label"
            />
          </b-form-group>
          <b-button
            block
            class="mb-2 mt-2"
            variant="primary"
            @click="
              $emit('adjust', {
                orderID: order.id,
                payment_intent: order.payment_intent,
                amount: amountItems,
                reason: selectedOrderCancelReasonItems.title,
                type: 'items',
              })
            "
            >ADJUST NOW
          </b-button>
        </b-tab>

        <b-tab title="Custom value">
          <b-form-group label="Custom Refund Amount" label-for="refund amount">
            <b-form-input
              v-model="amountCustom"
              placeholder="Enter amount to refund (e.g. 17)."
              type="number"
            />
          </b-form-group>
          <b-form-group>
            <v-select
              v-model="selectedOrderCancelReasonCustom"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="orderCancelReasonsCustom"
              :reduce="reason => reason.value"
              label="label"
            />
          </b-form-group>
          <b-button
            block
            class="mb-2 mt-2"
            variant="primary"
            @click="
              $emit('adjust', {
                orderID: order.id,
                payment_intent: order.payment_intent,
                amount: amountCustom,
                reason: selectedOrderCancelReasonCustom,
                type: 'custom',
              })
            "
          >
            <b-spinner v-if="spinner['adjust']" class="" small />
            <span
              >ADJUST NOW {{ selectedOrderCancelReasonCustom }} ${{ amountCustom || 0 }}</span
            >
          </b-button>
        </b-tab>
        {{ priceToAdjust }}
      </b-tabs>
    </b-modal>
  </b-button>
</template>

<script>
/* eslint-disable no-console */
import vSelect from 'vue-select'
import {
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormSpinbutton,
  BModal,
  BTabs,
  BTab,
  BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'AdjustOrder',
  components: {
    vSelect,
    BFormGroup,
    BFormSpinbutton,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BModal,
    BTabs,
    BTab,
    BFormInput,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOrderCancelReasonItems: 'requested_by_customer',
      orderCancelReasonsItems: [
        { label: 'Requested By Customer', value: 'requested_by_customer' },
        { label: 'Fraudulent', value: 'fraudulent' },
        { label: 'Duplicate', value: 'duplicate', icon: 'CodepenIcon' },
      ],
      selectedOrderCancelReasonCustom: 'requested_by_customer',
      orderCancelReasonsCustom: [
        { label: 'Requested By Customer', value: 'requested_by_customer' },
        { label: 'Fraudulent', value: 'fraudulent' },
        { label: 'Duplicate', value: 'duplicate' },
      ],
      priceToAdjust: 0,
      amountItems: null,
      amountCustom: null,
      showAdjustDialog: false,
      spinner: {
        adjust: false,
      },
    }
  },
  watch: {
    order: {
      handler(order) {
        // eslint-disable-next-line no-console
        console.log('order changed', order)
        this.priceToAdjust = this.calcaluteItemsPrice(order)
      },
      deep: true,
    },
  },
  methods: {
    calcaluteItemsPrice(order) {
      let subtotal = 0
      order.items.forEach(item => {
        if (!item.voided) {
          subtotal += item.price
          if (item.specialInstructions && item.specialInstructions.length > 0) {
            item.specialInstructions.forEach(instruction => {
              if (instruction.subtitles && instruction.subtitles.length > 0) {
                instruction.subtitles.forEach(subtitle => {
                  if (subtitle.voided === true) {
                    subtotal -= subtitle.price
                  }
                })
              }
            })
          }
        }
      })

      let discount = 0
      let tax = 0
      let total = 0

      if (order.discount_type?.includes('percent')) {
        discount = (subtotal * order.discount) / 100
      } else if (!order.discount_type?.includes('percent')) {
        discount = order.discount
      }

      if (order.loyalty_point_using) {
        subtotal -= order.loyalty_point_using
      }

      if (order.tax_rate) {
        tax = (subtotal * order.tax_rate) / 100
      }

      subtotal += order.service_fee

      total = subtotal + tax - discount
      return { subtotal, discount, tax, total }
    },
    calculateSpecialInstructionsPrice(specialInstructions) {
      let price = 0
      specialInstructions.forEach(item => {
        price += item.price
      })
      return price
    },
    async adjustOrder(order, type, reason, amount) {
      this.spinner.adjust = true
      try {
        /**
         * @type {Response}
         */
        const axiosResponse = await this.$http.post(`integrations/omnimenu/adjust-order/`, {
          order,
          type,
          amount,
          reason,
        })

        const { data } = axiosResponse
        if (data.type === 'success') {
          this.showToast('Successfully updated', 'CheckIcon', 'success')
        } else if (data.type === 'error') {
          console.error(data.display)
          this.showToast('Error!', 'AlertTriangleIcon', 'danger', data.display)
        }
      } catch (e) {
        console.error(e)
        this.showToast('Error!', 'AlertTriangleIcon', 'danger', e.message)
      }
      // stop spinner
      this.spinner.adjust = false
    },
  },
}
</script>

<style>
#full-width-checkbox-0 > div > label {
  width: 100%;
}
</style>
