<template>
  <b-row>
    <div
      v-if="items[0] && newOrderDialog"
      class="position-fixed d-flex justify-content-between flex-column p-5 dark-layout"
      style="
        left: 0;
        z-index: 999999999999999;
        right: 0;
        bottom: 0;
        top: 0;
        background: #12181f;
      "
    >
      <div class="d-flex justify-content-between">
        <b-avatar
          size="100px"
          :src="require('@/assets/images/logo/' + items[0].provider + '-icon-large.svg')"
        />
        <h2
          class=""
          style="
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #3b4253;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
          "
          @click="hideNewOrderDialog"
        >
          x
        </h2>
      </div>

      <div>
        <div class="font-large-3 font-weight-bolder h1">
          {{ items[0].full_name }} just ordered {{ items[0].totalItems }} items
        </div>
        <div
          class="font-large-1 text-white-50"
          style="
            max-width: 70vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ (items[0].items || []).map(item => item.name).join(', ') }}
        </div>
        <div
          class="font-large-1 h1 cursor-pointer user-select-none"
          style="
            max-width: 350px;
            white-space: nowrap;
            text-decoration: underline;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          @click="
            hideNewOrderDialog()
            order = items[0]
            orderDetailsDialog = true
          "
        >
          View order ->
        </div>
      </div>

      <div>
        <b-button size="xl" variant="primary" @click="hideNewOrderDialog"> Got it </b-button>
      </div>
    </div>
    <div class="d-flex justify-content-around flex-wrap col-12">
      <b-col md="2" sm="4" class="my-1" hidden>
        <b-form-group class="">
          <label class="d-inline-block mr-1">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group>
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-50 d-inline"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" :disabled="!sortBy" class="w-25 d-inline">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="5" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="filterInput"
          class=""
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </div>
    <b-col cols="12" class="dense">
      <b-table
        hover
        responsive
        show-empty
        :borderless="true"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        @row-clicked="onRowClicked"
      >
        <template #cell(provider)="data">
          <b-avatar
            size="lg"
            :src="require('@/assets/images/logo/' + data.value + '-icon-small.svg')"
          />
        </template>
        <template #cell(full_name)="data" class="hide-sm">
          <div class="text-nowrap overflow-hidden" style="max-width: 45vw">
            <div
              class="font-weight-bolder text-dark full_name"
              style="text-overflow: ellipsis; overflow: hidden"
            >
              {{ data.value }}
              <span
                class="font-weight-bolder font-medium-5"
                style="height: 1em; line-height: 1em"
                >•</span
              >
              {{ (data.item.store || {}).name }}
            </div>
            <div
              class="font-weight-bold text-dark order_id"
              style="text-overflow: ellipsis; overflow: hidden"
            >
              #{{ data.item.displayId.slice(-20) }}
            </div>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="text-center d-flex flex-column justify-content-end align-items-center">
            <b-button
              v-if="data.value === 'placed'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              pill
              size="sm"
              variant="outline-success"
              @click="updateOrder(data.item, 'confirmed', `/${data.item.displayId}`)"
            >
              <b-spinner v-if="data.item['confirmed_' + data.item.id] === true" small />
              <span class="">Accept</span>
            </b-button>
            <b-button
              v-else-if="data.value === 'confirmed'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-success"
              @click="updateOrder(data.item, 'ready')"
            >
              <b-spinner v-if="data.item['ready' + data.item.id] === true" small />
              <span class="">Ready</span>
            </b-button>
            <b-button
              v-else-if="data.value === 'ready'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-warning"
              @click="updateOrder(data.item, 'closed')"
            >
              <b-spinner v-if="data.item['closed' + data.item.id] === true" small />
              <span class="">Close</span>
            </b-button>
            <b-button
              v-if="!(data.item.status === 'cancelled' || data.item.status === 'closed')"
              v-ripple.400="'rgba(200, 150, 150, 0.20)'"
              pill
              size="sm"
              variant="outline-danger"
              class="mr-0"
              style="margin-top: 3px"
              @click="confirmCancelOrder(data.item)"
            >
              <b-spinner
                v-show="data.item['cancelled_' + data.item.id] === true"
                style="margin: 1px 3px"
                small
              />
              <span class="">Cancel</span>
            </b-button>
            <div
              class="font-weight-bold font-small-3 text-dark"
              style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap"
            ></div>
          </div>
        </template>

        <template #cell(isPickup)="data">
          <div class="text-center text-nowrap">
            <b-button
              v-if="
                (!data.item.delivery || data.item.delivery.requested !== true) &&
                data.item.isPickup !== 2
              "
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              pill
              size="sm"
              :disabled="data.item['new_' + data.item._id] === true"
              :variant="deliveryStatus[1][data.item.isPickup]"
              @click="callDriver(data.item._id, false, true)"
            >
              <b-spinner
                v-show="data.item['new_' + data.item._id] === true"
                style="margin: 1px 3px"
                small
              />
              <span class="font-medium-1">DD {{ deliveryStatus[0][data.item.isPickup] }}</span>
            </b-button>
            <div v-else-if="data.item.isPickup === 2">
              <h6>Pickup</h6>
            </div>
            <b-button
              v-else-if="data.item.delivery.provider === 'doordash_drive'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              pill
              size="sm"
              variant="outline-warning"
            >
              <span class="font-medium-1">DD Called</span>
            </b-button>
            <div
              v-if="!data.item.delivery || data.item.delivery.provider !== 'doordash_drive'"
              style="padding-top: 4px"
            >
              <b-button
                v-if="
                  data.item.delivery &&
                  data.item.delivery.requested === true &&
                  data.item.delivery.is_ready !== true
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                pill
                size="sm"
                :disabled="data.item['new_' + data.item._id] === true"
                variant="outline-success"
                @click="callDriver(data.item._id, true)"
              >
                <b-spinner v-if="data.item['ready_' + data.item._id] === true" small />
                <span class="font-medium-1">Mark as ready</span>
              </b-button>
              <b-button
                v-if="data.item.delivery && data.item.delivery.requested === true"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                pill
                style="margin: 2px"
                size="sm"
                variant="outline-danger"
                @click="voidDelivery(data.item._id)"
              >
                <b-spinner v-show="data.item['void_' + data.item._id] === true" small />
                <span class="font-medium-1">Void Delivery</span>
              </b-button>
              <b-button
                v-else-if="data.item.isPickup !== 2"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                pill
                size="sm"
                :variant="deliveryStatus[1][data.item.isPickup]"
                @click="callDriver(data.item._id)"
              >
                <b-spinner
                  v-show="data.item['new_' + data.item._id] === true"
                  style="margin: 1px 3px"
                  small
                />
                <span class="font-medium-1"
                  >Relay {{ deliveryStatus[0][data.item.isPickup] }}</span
                >
              </b-button>
            </div>
          </div>
        </template>
        <template #cell(placed)="data">
          <div class="text-center d-flex flex-column justify-content-end align-items-center">
            <div class="h5 mb-0 text-nowrap">
              {{
                ('0' + new Date(data.value).getDate()).slice(-2) +
                '/' +
                new Date(data.value).getMonth() +
                1
              }}
              <span class="font-medium-5" style="height: 1em; line-height: 1em">•</span>
              {{ new Date(data.value).toString().slice(16, 21) }}
            </div>
            <div>
              <div class="d-flex flex-column">
                <div
                  class="text-center text-nowrap"
                  style="margin-bottom: 4px; margin-top: 3px"
                >
                  <b-badge size="lg" pill :variant="status[1][data.item.status]">
                    {{ status[0][data.item.status] }}
                  </b-badge>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #cell(payment_method)="data">
          <div class="text-nowrap">
            <b-badge pill :variant="paymentStatus[1][data.item.payment_method]">
              {{ paymentStatus[0][data.item.payment_method] }}
            </b-badge>
          </div>
        </template>
        <template #cell(total)="data">
          <div class="h5 mb-0">
            {{ data.item.currencyCode ? data.item.currencyCode + ' ' : '$'
            }}{{ `${data.value.toFixed(2)}` }}
          </div>
        </template>

        <template #empty="">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingOrders" class="m-4">No orders are available...</h3>
          </div>
        </template>
        <template #emptyfiltered="scope">
          <div class="d-flex justify-content-center flex-column align-items-center">
            <h3 v-if="!loadingOrders" class="m-4">
              {{ scope.emptyFilteredText }}
            </h3>
          </div>
        </template>
      </b-table>
    </b-col>
    <div
      v-if="loadingOrders"
      class="d-flex justify-content-center flex-column align-items-center w-100 m-3"
    >
      <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
      <div class="m-2">Loading Orders, Please wait...</div>
    </div>
    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        size="sm"
        class="my-0 text-center d-flex justify-content-center"
      />
    </b-col>

    <DeliveryOrderDetailsPopup
      :order="order"
      :order-details-dialog="orderDetailsDialog"
      @close="orderDetailsDialog = false"
    />
    <b-modal
      v-model="cancelReasonDialog"
      title="Please select a reason to reject the order."
      centered
      @ok="cancelOrder"
    >
      <b-form-group label="Cancel Reason" label-for="cancel_reason">
        <v-select
          v-model="cancelReason"
          :menu-props="{ top: true, offsetY: true }"
          expanded
          :options="cancelReasonOptions"
          :clearable="false"
          input-id="cancel_reason"
        />
      </b-form-group>
    </b-modal>
  </b-row>
</template>
<style scoped></style>
<script>
/* eslint-disable no-console,no-param-reassign,no-underscore-dangle */
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBModal,
  BModal,
} from 'bootstrap-vue'
import DeliveryOrderDetailsPopup from '@/layouts/components/DeliveryOrderDetailsPopup.vue'
import Ripple from 'vue-ripple-directive'
import { AsYouType } from 'libphonenumber-js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import vSelect from 'vue-select'

// Fix up prefixing
window.AudioContext = window.AudioContext || window.webkitAudioContext

export default {
  components: {
    BModal,
    BSpinner,
    BBadge,
    BAvatar,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    vSelect,
    DeliveryOrderDetailsPopup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'provider',
          label: 'Pro.',
          sortable: true,
        },
        {
          key: 'full_name',
          label: 'Customer Name',
          sortable: true,
        },
        { key: 'status', label: 'Action', sortable: true },
        { key: 'placed', label: 'Status', sortable: true },
        { key: 'payment_method', label: 'Payment', sortable: true },
        { key: 'total', label: 'Total', sortable: true },
      ],
      items: [],
      status: [
        {
          placed: 'Placed',
          confirmed: 'Confirmed',
          ready: 'Ready',
          cancelled: 'Cancelled',
          closed: 'Closed',
        },
        {
          placed: 'light-secondary',
          confirmed: 'light-primary',
          ready: 'light-success',
          cancelled: 'light-danger',
          closed: 'dark',
        },
      ],
      paymentStatus: [
        {
          1: 'Paid by CC',
          2: 'Cash- Collect Payment',
        },
        {
          1: 'light-success',
          2: 'light-danger',
        },
      ],
      deliveryStatus: [
        {
          1: 'Driver',
          2: 'Pickup',
          3: 'Void Delivery',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'outline-primary',
          2: 'outline-warning',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      spinner: {
        ready: false,
        confirmed: false,
        cancelled: false,
        reprintKitchen: false,
        reprintBar: false,
        reprintCustomer: false,
        closed: false,
        adjust: false,
      },
      loadingOrders: true,
      cancelReason: 'Too busy',
      cancelReasonOptions: ['Too busy', 'Out of delivery area', 'Out of Stock'],
      cancelReasonDialog: false,
      selectedOrder: null,
      orderDetailsDialog: false,
      newOrderDialog: false,
      order: {
        items: [],
      },
      lastUpdate: new Date(new Date().getTime() - 5 * 60 * 60 * 1000).toISOString(),
      checkOrderInterval: undefined,
      keySoundBuffer: undefined,
      context: new AudioContext(),
      source: false,
      loadingButtons: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeDestroy() {
    clearInterval(this.checkOrderInterval)
    this.source = false
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  async created() {
    if (this.user.userData.settings && this.user.userData.settings.showDelivery) {
      this.fields.splice(3, 0, { key: 'isPickup', label: 'Delivery', sortable: true })
    }

    const This = this
    const url = 'audios/notification.mp3'
    const request = new XMLHttpRequest()
    request.open('GET', url, true)
    request.responseType = 'arraybuffer'
    request.onload = () => {
      This.context.decodeAudioData(
        request.response,
        buffer => {
          This.keySoundBuffer = buffer
        },
        () => {
          console.error('The request failed.')
        }
      )
    }
    request.send()

    function unlockAudioContext(ctx) {
      if (ctx.state !== 'suspended') return
      const b = document.body
      const events = ['touchstart', 'touchend', 'mousedown', 'keydown']
      // eslint-disable-next-line no-use-before-define
      events.forEach(e => b.addEventListener(e, unlock, false))
      function clean() {
        // eslint-disable-next-line no-use-before-define
        events.forEach(e => b.removeEventListener(e, unlock))
      }
      function unlock() {
        ctx.resume().then(clean)
      }
    }
    unlockAudioContext(this.context)

    await this.getOrders()
    if (this.checkOrderInterval) clearInterval(this.checkOrderInterval)
    this.checkOrderInterval = setInterval(async () => {
      await this.getOrders(true)
    }, 10000)
  },
  methods: {
    confirmCancelOrder(order) {
      this.$bvModal
        .msgBoxConfirm('Do you want to cancel this order?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'dark',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.selectedOrder = order
            this.cancelReasonDialog = true
          }
        })
    },
    cancelOrder() {
      console.log('cancelOrder', this.cancelReason, this.selectedOrder)
      if (!this.cancelReason || this.cancelReason === '' || !this.selectedOrder) {
        this.cancelReasonDialog = true
        return
      }
      this.updateOrder(this.selectedOrder, 'cancelled')
    },
    // eslint-disable-next-line no-unused-vars
    onRowClicked(item, index, event) {
      // eslint-disable-next-line no-underscore-dangle,no-param-reassign
      this.order = item
      this.orderDetailsDialog = !this.orderDetailsDialog
    },
    hideNewOrderDialog() {
      this.newOrderDialog = false
      if (this.source) {
        this.source.stop(0)
        this.source = false
      }
    },
    playSound(buffer, time, volume, ctx) {
      console.log('play now')
      if (ctx.state === 'suspended') return false
      console.log('playing')
      const source = ctx.createBufferSource() // creates a sound source
      source.buffer = buffer // tell the source which sound to play
      source.connect(ctx.destination) // connect the source to the context's destination (the speakers)
      source.start(time) // play the source at the deisred time 0=now
      source.loop = true
      return source
    },
    unimplemented(item) {
      this.$bvToast.toast('This is not implemented yet', {
        title: item,
        variant: 'warning',
        solid: true,
      })
    },
    async updateOrder(order2, update = 'ready', uidQuery = '/undefined', args) {
      const orderID = order2.id
      // if (update === 'cancel') return
      this.spinner[update] = true
      try {
        // start spinner
        this.items = this.items.map(order => {
          if (order.id === orderID) {
            order[`${update}_${orderID}`] = true
          }
          return order
        })
        let body = {}
        if (update === 'cancelled') body = { reason: this.cancelReason }
        /**
         * @type {Object}
         */
        let axiosResponse
        if (update === 'adjust') {
          axiosResponse = await this.$http.post(
            `integrations/${order2.provider}/adjust-order/`,
            {
              orderID,
              payment_intent: args.payment_intent,
              amount: args.amount,
              type: args.type,
              reason: args.reason,
            }
          )
        } else
          axiosResponse = await this.$http.patch(
            `integrations/${order2.provider}/update-order-status/${orderID}/${update}${uidQuery}`,
            body
          )
        const { data } = axiosResponse
        if (data.type === 'success') {
          if (update === 'adjust') {
            this.items = this.items.map(order => {
              if (order.id === orderID) {
                order.total -= args.amount
                order.adjustments = {
                  amount: args.amount,
                  reason: args.reason,
                }
              }
              return order
            })
          } else if (update === 'closed') {
            this.items = this.items.filter(order => order.id !== orderID)
          } else
            this.items = this.items.map(order => {
              if (order.id === orderID) order.status = update
              return order
            })
          this.showToast('Successfully updated', 'CheckIcon', 'success')
        } else if (data.type === 'error') {
          console.error(data.display)
          this.showToast('Error!', 'AlertTriangleIcon', 'danger', data.display)
        }
      } catch (e) {
        console.error(e)
        this.showToast('Error!', 'AlertTriangleIcon', 'danger', e.message)
      }
      // stop spinner
      this.spinner[update] = false
      this.items = this.items.map(order => {
        if (order.id === orderID) {
          order[`${update}_${orderID}`] = false
        }
        return order
      })
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async getOrders(hideRefreshing) {
      if (!hideRefreshing) this.loadingOrders = true
      try {
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`orders?time=${this.lastUpdate}`)
        response = response.data
        let { data } = response.orders[0]
        if (response.type === 'success' && response.orders && data.length > 0) {
          data = data.filter(order => {
            if (!order.provider || !order.store) {
              console.error(order)
              return false
            }
            return order.status !== 'closed'
          })
          data.sort(this.compare)
          const newOrders = data.map(
            /**
             *
             * @param {Object} order
             * @returns {Object & {note, delivery: *, placed: string, scheduled: *, isPickup: (number), delivery_instructions: *, tax: *, store: {}, deliver_by: string, total, printable_address: (*|string), full_name: *, provider: *, phone: string, subtotal: *, fullOrder: *, tip, _id: *, id, displayId, items, status: string}}
             */
            order => {
              if (order.placed > this.lastUpdate) this.lastUpdate = order.placed
              if (order.provider === 'omnimenu') order.printable_address = ''
              const orders = {
                // eslint-disable-next-line no-underscore-dangle
                _id: order._id,
                provider: order.provider,
                status: order.status.toLowerCase(),
                isPickup: order.is_pickup ? 2 : 1,
                full_name: order.full_name,
                placed: order.placed,
                deliver_by: new Date(order.deliver_by).toString().slice(16, 21),
                id: order.id,
                note: order.note,
                scheduled: order.scheduled,
                payment_method:
                  order.payment_method && order.payment_method.toLowerCase().includes('cash')
                    ? 2
                    : 1,
                delivery_instructions: order.delivery_instructions,
                subtotal: order.subtotal,
                tax: order.tax,
                tip: order.tip,
                total: order.total,
                phone: new AsYouType('US').input(`${order.phone}`),
                printable_address: !order.is_pickup ? order.address.printableAddress : '',
                displayId: order.displayId || order.id,
                store: order.store || {},
                items: order.items,
                fullOrder: order.fullOrder,
                delivery: order.delivery,
              }
              if (orders.items) {
                orders.totalItems = orders.items.reduce(
                  (total, current) => total + current.quantity,
                  0
                )
              }
              return Object.assign(order, orders)
            }
          )
          if (newOrders.length > 0) {
            if (this.items.length > 0) {
              if (!this.source)
                this.source = this.playSound(this.keySoundBuffer, 0, 1, this.context)
              this.items = newOrders.concat(this.items)
              this.newOrderDialog = true
            } else this.items = newOrders
          }
          this.totalRows = this.items.length
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        } else if (data && data.length !== 0) {
          console.error(response)
          this.$bvToast.toast('Something went wrong', {
            title: 'Error!',
            variant: 'info',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      }
      this.loadingOrders = false
    },
    async callDriver(orderID, markReady, doordash) {
      try {
        this.items = this.items.map(order => {
          if (order._id === orderID) {
            order[(markReady ? 'ready_' : 'new_') + orderID] = true
          }
          return order
        })
        /**
         * @type {Object}
         */
        let response
        if (!doordash) response = await this.$http.get(`delivery/new/${orderID}`)
        else response = await this.$http.get(`delivery/doordash/${orderID}`)
        response = response.data
        if (response.type === 'success') {
          this.items = this.items.map(order => {
            if (order._id === orderID) {
              order.delivery = order.delivery || {}
              order.delivery = {
                is_ready: response.is_ready,
                requested: true,
                provider: response.provider,
              }
            }
            return order
          })
          if (!response.is_ready) {
            this.$bvToast.toast(response.display, {
              title: 'Error!',
              variant: 'danger',
              solid: true,
            })
          }
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      }
      this.items = this.items.map(order => {
        if (order._id === orderID) {
          order[(markReady ? 'ready_' : 'new_') + orderID] = false
        }
        return order
      })
    },
    async voidDelivery(orderID) {
      try {
        this.items = this.items.map(order => {
          if (order._id === orderID) {
            order[`void_${orderID}`] = true
          }
          return order
        })
        console.log(this.loadingButtons)
        /**
         * @type {Object}
         */
        let response = await this.$http.get(`delivery/void/${orderID}`)
        response = response.data
        if (response.type === 'success') {
          this.items = this.items.map(order => {
            if (order._id === orderID) {
              order.delivery = order.delivery || {}
              order.delivery = { is_ready: false, requested: false }
            }
            return order
          })
        } else if (response.type === 'error') {
          console.error(response.display)
          this.$bvToast.toast(response.display, {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (e) {
        console.error(e)
        this.$bvToast.toast(e.message, {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        })
      }
      this.items = this.items.map(order => {
        if (order._id === orderID) {
          order[`void_${orderID}`] = false
        }
        return order
      })
    },
    /**
     * @param {String} a.placed
     * @param {String} b.placed
     * @returns {number}
     */
    compare(a, b) {
      if (a.placed > b.placed) {
        return -1
      }
      if (a.placed < b.placed) {
        return 1
      }
      return 0
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
