<template>
  <div>
    <orders-delivery v-show="deliveryOrDineIn === 'delivery'" />
    <orders-dine-in v-show="deliveryOrDineIn === 'dinein'" />
  </div>
</template>
<style scoped></style>
<script>
import { mapState } from 'vuex'
import OrdersDelivery from './OrdersDelivery.vue'
import OrdersDineIn from './OrdersDineIn.vue'

export default {
  components: {
    OrdersDelivery,
    OrdersDineIn,
  },
  data() {
    return {}
  },
  computed: mapState({
    deliveryOrDineIn: state => state.deliveryOrDineIn.deliveryOrDineIn,
  }),
  beforeDestroy() {},
  mounted() {},
  methods: {},
}
</script>
